<template>
	<div class="rootDiv">
		<div class="retrieve">
			<div class="searchDiv flex flex-jb">
				<div>
					<span>商品名称：</span>
					<el-input @keydown.enter.native="submitSearch" @clear="clearSearch" clearable class="w-450"
						placeholder="请输入商品名称" v-model="retrieveForm.searchValue">
						<el-button slot="append" type="primary" icon="el-icon-search" @click="submitSearch"></el-button>
					</el-input>
				</div>
				<div>
					<el-button type="primary" :disabled="disButton" @click="modEdit">批量修改</el-button>
				</div>
			</div>
		</div>
		<TableHeightFull class="tableDiv pall-30">
			<EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
				@row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
				<template v-slot:img="scope">
					<img :src="scope.row.boxImg" alt="" class="smallImg" v-if="scope.row.boxImg">
				</template>
				<template v-slot:coverImg="scope">
					<img :src="scope.row.seriesImg" alt="" class="smallImg" v-if="scope.row.seriesImg">
				</template>
			</EleTable>
		</TableHeightFull>
		<!-- 弹框 -->
		<el-dialog title="商品修改" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="30%"
			@close="resetForm">
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px">
				<el-form-item label="旧名称" prop="oldDuckName" v-if="'oldDuckName' in ruleForm"
					:rules="{required: true, message: '老名称不能为空', trigger: 'blur'}">
					<el-input :disabled="true" v-model="ruleForm.oldDuckName"></el-input>
				</el-form-item>
				<el-form-item label="新名称" prop="editDuckName" v-if="'editDuckName' in ruleForm"
					:rules="{required: true, message: '新名称不能为空', trigger: 'blur'}">
					<el-input v-model="ruleForm.editDuckName"></el-input>
				</el-form-item>
				<el-form-item label="商品图片" prop="boxImg" v-if="'boxImg' in ruleForm"
					:rules="{required: true, message: '请上传图片', trigger: 'blur'}">
					<UploadFile upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
						iconName="el-icon-upload" iconSize="26" v-model="ruleForm.boxImg"></UploadFile>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="affirmPopup" :loading="butLoading">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'editReard',
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
			"UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
			// "member-details": (resolve) => require(["./memberDetails"], resolve),
		},
		data() {
			return {
				retrieveForm: {
					searchValue: '',
				},
				disButton: true,
				butLoading: false,
				tabLoading: false,
				dialogFormVisible: false,
				ruleForm: {
					oldDuckName: '',
					editDuckName: '',
					boxImg: ''
				},
				columns: [{
						columnType: 'custom',
						label: '系列图片',
						prop: 'coverImg',
						align: 'left',
						width: '80'
					},
					{
						label: '系列名称',
						prop: 'seriesName',
						align: 'left',
						width: '300',
						'show-overflow-tooltip': true
					},
					{
						columnType: 'custom',
						label: '商品图片',
						prop: 'img',
						align: 'left',
						width: '80',
						'show-overflow-tooltip': true
					},
					{
						label: '商品名称',
						prop: 'ductName',
						align: 'left',
					},
					// {
					// 	columnType: 'button',
					// 	label: '操作',
					// 	align: 'right',
					// 	fixed: 'right',
					// 	width: '120',
					// 	buttons: [{
					// 			type: 'text',
					// 			text: '编辑',
					// 			fn: this.editList,
					// 		},
					// 		{
					// 			type: 'text',
					// 			text: '删除',
					// 			fn: this.deleteList,
					// 		}
					// 	],
					// },
				],
				tableData: [],
			}
		},
		methods: {
			//查询
			submitSearch() {
				this.tableData = []
				if (this.retrieveForm.searchValue == '') {
					this.disButton = true
					this.$message({
						message: '请输入商品名称',
						type: 'warning'
					})
				} else {
					this.getTableData()
				}
			},
			clearSearch() {
				this.retrieveForm.searchValue = ''
				this.tableData = []
				// this.getTableData()
			},
			//获取表格数据
			getTableData() {
				this.tabLoading = true
				this.$http.get('/goodSeries/findSeriesByDuckName', {
					params: {
						duckName: this.retrieveForm.searchValue,
					}
				}).then(({
					data: result
				}) => {
					this.tabLoading = false
					if (result.data.length == 0) {
						this.disButton = true
					} else {
						this.disButton = false
						this.tableData = result.data
					}
					// this.pageInfo.total = result.data.total
				}).catch(err => {
					this.tabLoading = false
				})
			},
			modEdit() {
				if (this.retrieveForm.searchValue == '' && !this.disButton) {
					this.$message({
						message: '请先搜索内容',
						type: 'warning'
					})
				} else {
					this.ruleForm.oldDuckName = this.retrieveForm.searchValue
					this.dialogFormVisible = true
				}
			},
			affirmPopup() {
				this.$confirm('您确认修改吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$refs['ruleForm'].validate((valid) => {
						if (valid) {
							let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
							this.$http.post('/goodSeries/editGoodDetailByDuckName', ruleForm).then(({
								data: result
							}) => {
								this.dialogFormVisible = false
								this.butLoading = false
								this.$message({
									message: '修改成功',
									type: 'success'
								})
								this.getTableData()
								this.initRedisData()
								this.resetForm()
							}).catch(err => {
								this.butLoading = false
							})
						}
					})
				}).catch(_ => {})
			},
			//刷新缓存
			initRedisData() {
				this.$http.post('/robot/initRedisData').then(() => {
					this.$message({
						message: '刷新成功',
						type: 'success'
					})
				})
			},
			//重置清除校验
			resetForm() {
				this.$data.ruleForm = this.$options.data.call(this).ruleForm
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate()
				})
			},
			headerRowClick(row) {
				console.log(row)
			},
		}
	}
</script>

<style lang="less" scoped>
</style>